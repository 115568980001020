.credits{
    &__form-wrapper{
        margin-top: 50px;
        width: 400px;
    }
    &__title{
        margin-bottom: 20px;
    }
    &__inputs-wrapper{
        margin-bottom: 10px;
    }
    &__inputs-wrapper--btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__input--btn-group{
        width: 160px;
        display: flex;
        justify-content: space-around;
    }
}