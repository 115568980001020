.coupon{
    &__info-wrapper{
        outline: 1px solid rgb(175, 169, 169);
        border-radius: 5px;
        width: 550px;
        padding: 25px;
        margin-top: 50px;
        
    }
    &__inputs-wrapper{
        width: 450px;
        display: flex;
        justify-content: space-between;
    }
    &__btn{
        height: 56px;
    }
}

.coupon-select{
    &__header{
        margin-bottom: 20px;
    }
    &__separate-header{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}