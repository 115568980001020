.coupon-form{
    &__wrapper{
        display: flex;
    }
    &__submit-button{
        margin-top: 20px;
    }
}

.coupon-activate-form{
    &__wrapper{
        width: 500px;
        margin-top: 40px;
    }
}