.inputs{
    &__wrapper{
        &--flex{
            display: flex;
            justify-content: space-between;
            width: 70%;
        }
        //width: 95%;

    }
}

.popup-form{
    &__wrapper{
        // max-width: 100% !important;
    }
    &__input{
        width: 70%;
        &--half{
            width: 45%;
            
        }
    }
}


.popup-form__wrapper1 {
    padding: 20px;
}

.popup-form__wrapper {
    width: 100%;
}

.inputs__wrapper {
    margin-bottom: 10px;
}

.popup-form__input {
    width: 100%;
}
