.inputs-wrapper{
    width: 600px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

#remark{
    width: 400px;
}

#url,
#script{
    width: 400px;
}