.login{
    &__wrapper{
        display: flex; 
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 90vh;

        @media (max-width: 768px) {
            height: 80vh;
        }

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: stretch;
        }
    }
}
