.form{
    &__inputs-wrapper{
        display: flex;
        justify-content: space-between;

        width: 80%;
    }
    &__input{
        width: 70%;
        &--small{
            width: 40%;
        }
    }
}